//deps
import { Button, Icon, IconButton } from "@chakra-ui/react";
import { IoDownloadOutline } from "react-icons/io5";
import { useIntl } from "react-intl";

// hooks
import useRequest from "@raiden/library-ui/hooks/useRequest";

// utils
import downloadBlob from "@raiden/library-ui/libraries/utils/downloadBlob";

// helpers
import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import { useTaskDrawer } from "../../hooks/useTaskDrawer";

/**
 * @typedef {object} Props
 * @property {string} endpointUrl
 * @property {string} [label]
 * @property {string} [filename]
 * @property {string} [colorScheme]
 * @property {string} [variant]
 * @property {import("@raiden/library-ui/hooks/useApiFetcher").OutputMode} [outputMode]
 * @property {import("@raiden/library-ui/hooks/useApiFetcher").Init} [init]
 * @property {string} [size]
 * @property {any} [icon]
 * @property {string} [ariaLabel]
 */

/**
 * @returns {import ("react").FunctionComponentElement<Props>}
 * @param {Props} props
 */

export default function ButtonDownloadFile(props) {
  const {
    endpointUrl,
    label,
    filename = "export.csv",
    ariaLabel = "Télécharger le fichier",
    outputMode = "csv",
    init = {},
    ...otherProps
  } = props;

  const intl = useIntl();

  const { onTaskDrawerOpen } = useTaskDrawer();

  const { request, isLoading, toastSuccess } = useRequest();

  /**
   * Gère la soumission du formulaire.
   */
  function handleClick() {
    request(
      endpointUrl,
      {
        method: init.method ?? "GET",
        body: init.body,
        ...(init.credentials && {
          credentials: init.credentials,
        }),
      },
      {
        outputMode,
      },
    )
      .then(function (response) {
        if (response?.contentType === "blob" && response?.blob) {
          downloadBlob({
            blob: response?.blob,
            filename: response?.filename || filename,
          });
          toastSuccess({
            title: intl.formatMessage({
              defaultMessage:
                "Le fichier vient d'être généré et le téléchargement se lance.",
            }),
          });
        } else {
          toastSuccess({
            title: intl.formatMessage({
              defaultMessage:
                "Le fichier va être généré. Vous pouvez suivre l'avancement dans le panneau de tâches.",
            }),
          });
          onTaskDrawerOpen({
            fields: {
              only_id: [response.data.task_id],
            },
          });
        }
      })
      .catch((response) => {
        toastSuccess({
          title: response?.body?.errors?.[0]?.detail
            ? apiGetErrorDetail({ error: response })
            : intl.formatMessage({
                defaultMessage:
                  "Une erreur est survenue lors du téléchargement du fichier.",
              }),
          status: "error",
        });
      });
  }

  if (label) {
    return (
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        leftIcon={<Icon as={IoDownloadOutline} />}
        colorScheme="brandSecondary"
        size="sm"
        {...otherProps}>
        {label}
      </Button>
    );
  }

  return (
    <IconButton
      isLoading={isLoading}
      onClick={handleClick}
      as="button"
      size="sm"
      colorScheme="brandSecondary"
      aria-label={ariaLabel}
      icon={<Icon as={IoDownloadOutline} />}
      {...otherProps}
    />
  );
}
