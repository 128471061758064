import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { TASKS_SORT_VALUE_CREATED_DESC } from "@raiden/library-ui/constants/tasks";
import { SearchProvider } from "@raiden/library-ui/contexts/Search";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useIntl } from "react-intl";
import { TASKS_REFRESH_INTERVAL } from "../../../contexts/Tasks";
import { useTaskDrawer } from "../../../hooks/useTaskDrawer";
import { TaskDrawerBody } from "./DrawerBody";

function endpointUrl({ fields, customFields }) {
  return generateApiUrl({
    id: "@api.tasks.viewAny",
    query: {
      ...fields,
      ...customFields,
      fields: ["task.policies", "task.object"],
    },
  });
}

/**
 * @returns {import("react").FunctionComponentElement}
 */
export function TaskDrawer(props) {
  const intl = useIntl();

  const {
    isTaskDrawerOpen,
    onTaskDrawerClose,
    fields: customFields,
  } = useTaskDrawer();

  function getEndpointUrl({ fields }) {
    return endpointUrl({ fields, customFields });
  }

  return (
    <Drawer
      size="xl"
      isOpen={isTaskDrawerOpen}
      placement="right"
      onClose={onTaskDrawerClose}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader borderBottomWidth="1px">
          {intl.formatMessage({
            defaultMessage: "Tâches en cours",
          })}
        </DrawerHeader>

        <SearchProvider
          searchId="task"
          endpointUrl={getEndpointUrl}
          refreshInterval={TASKS_REFRESH_INTERVAL}
          routerSyncUrl={false}
          paginationUrl={false}
          useSessionStorageHydration={false}
          defaultValues={{
            sort: TASKS_SORT_VALUE_CREATED_DESC,
            state: [],
            type: [],
            only_id: [],
          }}>
          <TaskDrawerBody />
        </SearchProvider>
      </DrawerContent>
    </Drawer>
  );
}
