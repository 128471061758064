// deps
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useToast } from "@chakra-ui/react";
import useApiFetcher from "./useApiFetcher";

/**
 * Hook utilisé pour faciliter l'envoi des requêtes vers l'API
 *
 * - isLoading: booléen indiquant si une requête est en cours
 * - toastSuccess: callback pour afficher un toast de succès préconfiguré
 */

/**
 * @callback UseRequestFunction
 * @param {string} url
 * @param {RequestInit | import("./useApiFetcher").Init} [init]
 * @param {import("../hooks/useApiFetcher").FetchOptions} [options]
 * @returns {Promise<import("../types/Api/ApiResponse").ApiResponse>}
 */

/**
 * @typedef {object} UseRequestOptions
 * @property {boolean} [abortOngoingRequests]
 */

/**
 * @param {UseRequestOptions} options
 */
function useRequest({ abortOngoingRequests = false } = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const apiFetcher = useApiFetcher();
  const toast = useToast();

  const [previousController, setPreviousController] = useState(
    /** @type {AbortController | null} */ (null),
  );

  /** @type {(options: import("@chakra-ui/react").UseToastOptions) => void} */
  const toastSuccess = useCallback(
    (options) => {
      toast({
        status: "success",
        position: "bottom",
        duration: 5000,
        isClosable: true,
        ...options,
      });
    },
    [toast],
  );

  /** @type {(options: import("@chakra-ui/react").UseToastOptions) => void} */
  const toastError = useCallback(
    (options) => {
      toast({
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
        ...options,
      });
    },
    [toast],
  );

  /** @type {UseRequestFunction} */
  const request = useCallback(
    (url, init, options = {}) => {
      const controller = new AbortController();

      abortOngoingRequests && previousController?.abort();

      setPreviousController(controller);

      setIsLoading(true);

      return apiFetcher(
        url,
        { ...init, signal: controller.signal },
        options,
      ).finally(() => {
        setIsLoading(false);
      });
    },
    [abortOngoingRequests, apiFetcher, previousController],
  );

  return {
    isLoading,
    request,
    toastSuccess,
    toastError,
  };
}

useRequest.propTypes = {
  children: PropTypes.node,
};

useRequest.defaultProps = {};

export default useRequest;
