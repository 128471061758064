import {
  Box,
  ButtonGroup,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";
import ResourceState from "@raiden/library-ui/components/Resource/State";
import { SHORT_DATETIME_OPTIONS } from "@raiden/library-ui/constants/intl";
import {
  TASKS_OBJECT_TYPES,
  TASKS_STATES,
} from "@raiden/library-ui/constants/tasks";
import {
  getTaskProgress,
  getTaskProgressLabel,
} from "@raiden/library-ui/helpers/tasks";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { MdOutlineCancel, MdOutlineRedo, MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";
import ButtonDownloadFile from "../../..//components/ButtonDownloadFile";
import { TasksActionsCancel } from "../Actions/Cancel";
import { TasksActionsRetry } from "../Actions/Retry";
import { TasksActionsView } from "../Actions/View";

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Task").Task} resource
 * @property {() => void} mutate
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function TaskSearchItem({ resource, mutate }) {
  const intl = useIntl();

  return (
    <Table.Tr key={resource.id}>
      <Table.Td>
        <Flex alignItems="center">
          {TASKS_OBJECT_TYPES[resource?.object_type]?.icon && (
            <Icon
              as={TASKS_OBJECT_TYPES[resource.object_type].icon}
              mr="0.5rem"
            />
          )}

          <Flex flexDir="column">
            {resource.type_label ? (
              <Text>{resource.type_label}</Text>
            ) : (
              <Text>
                {intl.formatMessage({ defaultMessage: "Tâche inconnue" })}
              </Text>
            )}

            {resource?.object?.display_name && (
              <Text fontSize=".75rem">{resource?.object?.display_name}</Text>
            )}
          </Flex>
        </Flex>
      </Table.Td>

      <Table.Td>
        <Text>
          <ResourceState state={resource.state} states={TASKS_STATES} />
        </Text>
      </Table.Td>

      <Table.Td>
        {resource.progress !== null && resource.max_progress !== null ? (
          <Tooltip
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{taskProgression} {maxProgress, plural, =0 {tâche} other {tâches}}",
              },
              {
                maxProgress: resource.max_progress,
                taskProgression: `${resource.progress}/${resource.max_progress}`,
              },
            )}
            placement="bottom-start">
            <Box
              mx={{ base: "0", xl: "auto" }}
              cursor="pointer"
              w="min-content"
              borderRadius="full">
              <CircularProgress
                value={getTaskProgress({ task: resource })}
                color={TASKS_STATES[resource.state].colors[0]}>
                <CircularProgressLabel>
                  {getTaskProgressLabel({ task: resource })}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Tooltip>
        ) : (
          <Text>
            {intl.formatMessage({
              defaultMessage: "Non renseigné",
            })}
          </Text>
        )}
      </Table.Td>

      <Table.Td>
        <Flex direction="column" gap="5px">
          {resource?.created_at && (
            <Text>
              {intl.formatDate(resource.created_at, SHORT_DATETIME_OPTIONS)}
            </Text>
          )}

          {resource?.started_at && (
            <Text>
              {intl.formatDate(resource.started_at, SHORT_DATETIME_OPTIONS)}
            </Text>
          )}
        </Flex>
      </Table.Td>

      <Table.Td>
        <ButtonGroup>
          <TasksActionsView resource={resource} mutate={mutate}>
            {({ disclosure }) => (
              <Tooltip
                label={intl.formatMessage({
                  defaultMessage: "Voir le détail",
                })}>
                <Box>
                  <IconButton
                    size="sm"
                    variant="outline"
                    colorScheme="darkGray"
                    aria-label={intl.formatMessage({
                      defaultMessage: "Voir le détail",
                    })}
                    icon={<Icon as={MdSearch} />}
                    onClick={disclosure.onOpen}
                  />
                </Box>
              </Tooltip>
            )}
          </TasksActionsView>

          {resource.results?.export_name && resource.results?.export_url && (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage: "Télécharger",
              })}>
              <Wrap>
                <ButtonDownloadFile
                  variant="outline"
                  outputMode="file"
                  colorScheme="darkGray"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Télécharger le fichier csv",
                  })}
                  size="sm"
                  endpointUrl={resource.results.export_url}
                  filename={resource.results.export_name}
                  init={{ credentials: "omit" }}
                />
              </Wrap>
            </Tooltip>
          )}

          <TasksActionsCancel resource={resource} onSuccess={mutate}>
            {function ({ disclosure }) {
              return (
                <Box>
                  <Tooltip
                    label={intl.formatMessage({
                      defaultMessage: "Annuler la tâche",
                    })}>
                    <IconButton
                      size="sm"
                      variant="outline"
                      colorScheme="darkGray"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Annuler la tâche",
                      })}
                      icon={<Icon as={MdOutlineCancel} />}
                      onClick={disclosure.onOpen}
                    />
                  </Tooltip>
                </Box>
              );
            }}
          </TasksActionsCancel>

          <TasksActionsRetry resource={resource} onSuccess={mutate}>
            {function ({ disclosure }) {
              return (
                <Box>
                  <Tooltip
                    label={intl.formatMessage({
                      defaultMessage: "Réesayer la tâche",
                    })}>
                    <IconButton
                      size="sm"
                      variant="outline"
                      colorScheme="darkGray"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Réesayer la tâche",
                      })}
                      icon={<Icon as={MdOutlineRedo} />}
                      onClick={disclosure.onOpen}
                    />
                  </Tooltip>
                </Box>
              );
            }}
          </TasksActionsRetry>
        </ButtonGroup>
      </Table.Td>
    </Table.Tr>
  );
}
