/* istanbul ignore file */

// deps
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

// constants
import { SHORT_DATE_OPTIONS } from "../../constants/intl";

// contexts
import { useSearch } from ".";

function getLabelNode({ $field, searchId }) {
  let $node = $field;
  let $label = null;

  do {
    $node = $node.parentNode;
    $label = $node.querySelector(`[data-search-id=${searchId}]`);
  } while (!$label && $node);

  return $label;
}

export default function SearchTags(props) {
  const { onClick } = props;

  const { submittedFields, form, searchId, onSubmit, tagsRefreshId } =
    useSearch();
  const intl = useIntl();

  const [tagList, setTagList] = useState([]);

  /**
   * Récupère et affiche les tags avec les labels correspondants.
   */
  useEffect(
    function () {
      setTagList(
        Object.entries(submittedFields).reduce(function (
          tagList,
          [fieldKey, fieldValue],
        ) {
          const cssEscapedFieldKey = CSS.escape(fieldKey);

          const fieldList = Array.from(
            document.querySelectorAll(
              [
                `[data-search-id=${searchId}] ~ .chakra-input__group > input[name=${cssEscapedFieldKey}]`,
                `[data-search-id=${searchId}] ~ .chakra-input__group > textarea[name=${cssEscapedFieldKey}]`,
                `[data-search-id=${searchId}] ~ .chakra-input__group > .chakra-select__wrapper > select[name=${cssEscapedFieldKey}]`,
                `[data-search-id=${searchId}] ~ input[name=${cssEscapedFieldKey}]`,
                `[data-search-id=${searchId}] ~ textarea[name=${cssEscapedFieldKey}]`,
                `[data-search-id=${searchId}] ~ .chakra-select__wrapper > select[name=${cssEscapedFieldKey}]`,
              ].join(", "),
            ),
          );

          tagList.push(
            ...[...fieldList].reduce(function (
              tagPair,
              $field,
              fieldIndex,
              fieldList,
            ) {
              const $label = getLabelNode({ $field, searchId: searchId });

              if ($label?.innerText) {
                if (
                  $field instanceof HTMLInputElement ||
                  $field instanceof HTMLTextAreaElement
                ) {
                  switch ($field.type) {
                    case "checkbox": {
                      // @ts-ignore
                      if ($field.checked) {
                        if ($label?.dataset?.searchTagActive) {
                          // @ts-ignore
                          tagPair.push([
                            $label.dataset.searchTagActive,
                            null,
                            fieldKey,
                          ]);
                        } else {
                          // @ts-ignore
                          tagPair.push([
                            $label.innerText,
                            $field.value,
                            fieldKey,
                          ]);
                        }
                      }

                      break;
                    }

                    case "date": {
                      if ($field.value) {
                        const date = Date.parse($field.value);

                        if (!isNaN(date)) {
                          // @ts-ignore
                          tagPair.push([
                            $label.innerText,
                            intl.formatDate(date, SHORT_DATE_OPTIONS),
                            fieldKey,
                          ]);
                        }
                      }

                      break;
                    }

                    case "hidden": {
                      const value = $field.dataset?.value ?? $field.value;

                      if (value) {
                        // @ts-ignore
                        if ($field._newValue) {
                          // @ts-ignore
                          tagPair.push([
                            $label.innerText,
                            value,
                            fieldKey,
                            // @ts-ignore
                            $field._newValue,
                          ]);
                        } else {
                          // @ts-ignore
                          tagPair.push([$label.innerText, value, fieldKey]);
                        }
                      }

                      break;
                    }

                    default: {
                      const value = $field.dataset?.value ?? $field.value;

                      if (value) {
                        // @ts-ignore
                        tagPair.push([$label.innerText, value, fieldKey]);
                      }
                    }
                  }
                } else if ($field instanceof HTMLSelectElement) {
                  if ($field.value) {
                    const $selectedOption = Array.from(
                      $field.querySelectorAll("option"),
                    ).find(function ($option) {
                      return $option.value === $field.value;
                    });

                    if ($selectedOption?.innerText) {
                      // @ts-ignore
                      tagPair.push([
                        $label.innerText,
                        $selectedOption.innerText,
                        fieldKey,
                      ]);
                    }
                  }
                }
              }

              return tagPair;
            },
            []),
          );

          return tagList;
        },
        []),
      );
    },
    [intl, searchId, submittedFields, tagsRefreshId],
  );

  return (
    <Wrap maxWidth="calc(100% - 1rem)">
      {
        // @ts-ignore
        tagList.map(function ([fieldLabel, fieldValue, fieldKey, newValue]) {
          return (
            <WrapItem key={`tag-${fieldLabel}-${fieldValue}`} maxW="full">
              <Tag fontSize="0.875rem" borderRadius={"full"} maxW="full">
                <TagLabel>
                  {fieldLabel && fieldValue
                    ? intl.formatMessage(
                        {
                          id: "raiden.admin.contexts.Search.texts.tag",
                          defaultMessage: '"{fieldLabel}" : {fieldValue}',
                        },
                        { fieldLabel, fieldValue },
                      )
                    : fieldLabel}
                </TagLabel>
                <TagCloseButton
                  onClick={function () {
                    const preventDefault = onClick({
                      fieldLabel,
                      fieldValue,
                      fieldKey,
                      newValue,
                    });

                    if (!preventDefault) {
                      if (newValue) {
                        form.setValue(fieldKey, newValue);
                      } else {
                        // @ts-ignore
                        form.setValue(fieldKey, "");
                      }
                      onSubmit();
                    }
                  }}
                />
              </Tag>
            </WrapItem>
          );
        })
      }
    </Wrap>
  );
}

SearchTags.propTypes = {
  onClick: PropTypes.func,
};

SearchTags.defaultProps = {
  onClick() {
    return false;
  },
};
